<template>
  <DatePicker
    v-if="props.type === 'date'"
    v-bind="$attrs"
    ref="datePickerRef"
    class="elv-transaction-date-picker"
    :bordered="false"
    :locale="locale === 'en' ? enUS : zhCN"
    popup-class-name="elv-select-transaction-filter-datetime-dropdown date"
    :show-today="false"
    :value-format="props.valueFormat"
    :format="inputFormat"
  >
  </DatePicker>
  <RangePicker
    v-else
    ref="rangPickerRef"
    v-bind="$attrs"
    class="elv-transaction-picker-range"
    :locale="locale === 'en' ? enUS : zhCN"
    :bordered="false"
    :presets="rangePresets"
    :value-format="props.valueFormat"
    :input-format="inputFormat"
    :format="inputFormat"
    popup-class-name="elv-select-transaction-filter-datetime-dropdown"
    @calendarChange="onCalendarChange"
  >
    <template #separator>{{ rangePickerValue }}</template>
  </RangePicker>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import type { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { DatePicker, RangePicker } from 'ant-design-vue'
import zhCN from 'ant-design-vue/es/date-picker/locale/zh_CN'
import enUS from 'ant-design-vue/es/date-picker/locale/en_US'
import { useReportStore } from '@/stores/modules/reports/index'

defineOptions({
  inheritAttrs: false
})

const props = defineProps({
  type: {
    type: String,
    default: 'range'
  },
  enableDisabled: {
    type: Boolean,
    default: true
  },
  valueFormat: {
    type: String,
    default: 'YYYY-MM-DD'
  },
  isShowTime: {
    type: Boolean,
    default: false
  }
})

defineEmits(['update:modelValue'])

type RangeValue = [string, string] | [Dayjs, Dayjs]

dayjs.extend(utc)
dayjs.locale('en')
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
const attrs: any = useAttrs()
const { locale } = useI18n()
// eslint-disable-next-line no-unused-vars
const reportStore = useReportStore()
const instance: any = getCurrentInstance()

const dateTimes = ref()
const datePickerRef = useTemplateRef('datePickerRef')
const rangPickerRef = useTemplateRef('rangPickerRef')

const rangePresets = ref([
  { label: 'Last 7 Days', value: [dayjs().add(-6, 'd'), dayjs()] },
  { label: 'Last 30 Days', value: [dayjs().add(-29, 'd'), dayjs()] },
  { label: 'Last 90 Days', value: [dayjs().add(-79, 'd'), dayjs()] },
  { label: 'This Year', value: [dayjs().startOf('year'), dayjs().endOf('year')] }
])

const inputFormat = computed(() => {
  if (props.isShowTime) return 'YYYY/MM/DD HH:mm'
  if (props.type === 'date') return 'YYYY/MM/DD'
  let format = 'YYYY/MM/DD'
  switch (attrs.picker) {
    case 'month':
      format = 'YYYY/MM'
      break
    case 'quarter':
      format = 'YYYY-[Q]Q'
      break
    default:
      break
  }
  return format
})

const rangePickerValue = computed(() => {
  if (!attrs.value?.length) return ''
  const start = dayjs(attrs.value[0]).format(props.isShowTime ? 'YYYY/MM/DD  HH:mm' : 'YYYY/MM/DD')
  const end = dayjs(attrs.value[1]).format(props.isShowTime ? 'YYYY/MM/DD  HH:mm' : 'YYYY/MM/DD')
  return `${start} - ${end}`
})

const onCalendarChange = (val: RangeValue) => {
  dateTimes.value = val
}

const resetDateTime = () => {
  dateTimes.value = undefined
}

defineExpose({ ...instance.exposed, resetDateTime })

watch(locale, (val) => {
  dayjs.locale(val)
})

watch(
  () => props.type,
  () => {
    nextTick(() => {
      const entries =
        props.type === 'date'
          ? Object.entries(datePickerRef.value?.$?.exposed || {})
          : Object.entries(rangPickerRef.value?.$?.exposed || {})
      for (const [key, value] of entries) {
        instance.exposed[key] = value
      }
    })
  },
  { immediate: true }
)
</script>

<style lang="scss">
.ant-picker-dropdown {
  z-index: 4444;

  &.elv-select-transaction-filter-datetime-dropdown {
    padding: 0;

    .ant-picker-range-arrow {
      display: none;
    }

    .ant-picker-panel-container {
      border-radius: 4px;
      border: 1px solid #e4e7eb;
      background: #fff;
      box-shadow:
        0px 0px 1px 0px rgba(0, 0, 0, 0.15),
        0px 4px 8px 0px rgba(0, 0, 0, 0.04),
        0px 8px 16px 0px rgba(0, 0, 0, 0.04);

      .ant-picker-panel-layout {
        display: flex;

        .ant-picker-presets {
          width: 100px !important;
          min-width: 100px !important;
          box-sizing: border-box;
          border-right: 1px solid #edf0f3;

          ul {
            box-sizing: border-box;
            width: 100%;
            border-right: 0px;
            padding: 0px;

            li {
              height: 32px;
              box-sizing: border-box;
              padding: 0 8px !important;
              margin-right: 0px !important;
              display: flex;
              align-items: center;
              justify-content: left;
              color: #636b75;
              font-family: 'Plus Jakarta Sans';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 13px;

              &:hover {
                background: #e6edff;
                color: #1753eb;
              }
            }
          }
        }

        .ant-picker-panels {
          padding: 8px;
        }
      }
    }

    .ant-picker-header {
      padding: 0 6px;
      border-bottom: 1px solid #edf0f3;

      button {
        line-height: 34px;
      }
    }

    .ant-picker-header-view {
      line-height: 34px;

      .ant-picker-month-btn {
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-weight: 700;
        // line-height: 14px;
        color: #1e2024;
      }

      .ant-picker-year-btn {
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-weight: 500;
        // line-height: 14px;
        color: #1e2024;
      }

      .ant-picker-decade-btn {
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-weight: 500;
        // line-height: 14px;
        color: #1e2024;
      }
    }

    .ant-picker-header-view:has(.ant-picker-month-btn) {
      .ant-picker-year-btn {
        color: #aaafb6;
      }
    }

    .ant-picker-date-panel {
      width: 184px;

      .ant-picker-content {
        width: 184px;

        th {
          width: 26px;
        }
      }
    }

    .ant-picker-time-panel {
      .ant-picker-content {
        .ant-picker-time-panel-cell-selected {
          border-bottom: 1px solid #1343bf;

          .ant-picker-time-panel-cell-inner {
            background: #fff;
            color: #0e0f11;
            font-weight: 600;
          }
        }
      }
    }

    .ant-picker-panel:nth-of-type(2) {
      margin-left: 16px;
    }

    .ant-picker-date-panel .ant-picker-body {
      padding: 6px 0 8px 0;

      thead tr {
        th {
          font-family: 'Barlow';
          font-size: 11px;
          font-weight: 500;
          line-height: 11px;
          color: #aaafb6;
        }
      }

      .ant-picker-cell {
        // visibility: hidden;
        // &.ant-picker-cell-in-view {
        //   visibility: visible;
        // }
        padding: 0px;
        height: 28px;
        box-sizing: border-box;

        .ant-picker-cell-inner {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          height: 100%;
          box-sizing: border-box;
          border-radius: 0px !important;
        }

        &.ant-picker-cell-in-view {
          .ant-picker-cell-inner {
            color: #636b75;
          }

          &:not(.ant-picker-cell-range-start, .ant-picker-cell-range-end).ant-picker-cell-selected {
            .ant-picker-cell-inner {
              background: #fff;
              border-bottom: 1px solid #1343bf;
              color: #0e0f11;
              font-weight: 600;
            }
          }
        }

        &.ant-picker-cell-today .ant-picker-cell-inner {
          &::before {
            border: none;
          }
        }

        &.ant-picker-cell-range-start,
        &.ant-picker-cell-range-end,
        &.ant-picker-cell-in-range {
          border-bottom: 1px solid #1343bf;

          &::before {
            background-color: #fff;
          }

          .ant-picker-cell-inner {
            color: #0e0f11;
            font-weight: 600;
            background-color: #fff;
          }
        }

        &.ant-picker-cell-range-hover-end,
        &.ant-picker-cell-range-hover-start {
          &::after {
            inset-inline-end: 1px !important;
            inset-inline-start: 1px !important;
          }
        }

        &.ant-picker-cell-in-range {
          &.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
          &.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
            inset-inline-end: 0px;
            inset-inline-start: 0px;
          }
        }
      }
    }

    .ant-picker-year-panel,
    .ant-picker-month-panel,
    .ant-picker-quarter-panel {
      width: 184px;

      .ant-picker-body {
        padding: 0px;
        box-sizing: border-box;

        .ant-picker-content {
          height: fit-content;
        }

        .ant-picker-cell-inner {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          box-sizing: border-box;
          color: #d9dbdf;
          border-radius: 0px !important;
          width: 60px;
          height: 53px;
          padding: 0px;
        }

        .ant-picker-cell {
          padding: 0px;
          height: 53px;

          &::before {
            height: 48px;
          }

          &.ant-picker-cell-in-view {
            .ant-picker-cell-inner {
              color: #636b75;
            }

            &:not(.ant-picker-cell-range-start, .ant-picker-cell-range-end).ant-picker-cell-selected {
              .ant-picker-cell-inner {
                background: #fff;
                border-bottom: 1px solid #1343bf;
                color: #0e0f11;
                font-weight: 600;
              }
            }
          }

          &.ant-picker-cell-range-start,
          &.ant-picker-cell-range-end,
          &.ant-picker-cell-in-range {
            border-bottom: 1px solid #1343bf;

            &::before {
              background-color: #fff;
            }

            .ant-picker-cell-inner {
              color: #0e0f11;
              font-weight: 600;
              background-color: #fff;
            }
          }

          &.ant-picker-cell-range-hover-end,
          &.ant-picker-cell-range-hover-start,
          &.ant-picker-cell-range-hover {
            &::after {
              height: 48px;
              inset-inline-end: 1px !important;
              inset-inline-start: 1px !important;
            }
          }

          &.ant-picker-cell-in-range {
            &.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
            &.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
              height: 48px;
              inset-inline-end: 0px;
              inset-inline-start: 0px;
            }
          }
        }
      }
    }

    &.date {
      .ant-picker-date-panel {
        width: fit-content;
        box-sizing: border-box;
        padding: 8px;

        .ant-picker-content {
          width: 312px;

          th {
            width: 44.6px;
          }
        }
      }
    }
  }
}

.ant-picker {
  padding: 0px 12px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  &.elv-transaction-picker-range {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;

    .ant-picker-input {
      display: none;
    }

    .ant-picker-range-separator {
      padding: 0px;
      width: 278px;
      cursor: pointer;
    }

    &:hover {
      border-color: #5e85eb;
    }

    &.ant-picker-focused {
      border-color: #5e85eb;
    }

    .ant-picker-active-bar {
      height: 0px;
    }

    .ant-picker-clear {
      inset-inline-end: 13px;
    }

    .ant-picker-input > input {
      height: 28px;
      color: #1e2024;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }

    .ant-picker-suffix {
      svg {
        width: 16px;
        height: 16px;
        fill: #838d95;
        transition: transform 0.3s ease-in-out;
      }
    }
  }
}
</style>
